import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import BeatLoader from "react-spinners/BeatLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SAVE_ABB_POPUP = ({
  popUpSaveABBOpen,
  loadingSaveABB,
  handlePopUpSaveABBClose,
  fileSavedName,
  setFileSavedName,
  errors,
  handleSaveNewABB,
}) => {
  return (
    <>
      <Dialog
        open={popUpSaveABBOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="lg"
        keepMounted
        onClose={handlePopUpSaveABBClose}
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            textAlign: "center",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              fontFamily: "Calibri",
              fontSize: 25,
            }}
          >
            <div style={{ color: "#1976d2", fontFamily: "Calibri" }}>
              CONFERMI DI VOLER SALVARE L'ABBINATO ELABORATO?
            </div>
          </DialogContentText>
          <div style={{ textAlign: "center", paddingTop: "1%" }}>
            <TextField
              id="file_name"
              label="Nome"
              placeholder="Inserisci il Nome del Salvataggio"
              sx={{
                width: "40%",
              }}
              value={fileSavedName}
              onChange={(e) => setFileSavedName(e.target.value)}
              error={errors.fileSavedName ? true : false}
              helperText={errors.fileSavedName}
            />
          </div>
          <div
            style={{
              textAlign: "right",
              paddingTop: "1%",
              paddingRight: "0.5%",
              display: "inline-block",
              width: "50%",
            }}
          >
            <Button
              variant="contained"
              color="redtest"
              onClick={handlePopUpSaveABBClose}
            >
              INDIETRO
            </Button>
          </div>
          <div
            style={{
              textAlign: "left",
              paddingTop: "1%",
              display: "inline-block",
              width: "50%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveNewABB}
            >
              CONFERMA
            </Button>
          </div>

          {loadingSaveABB && (
            <div style={{ paddingTop: "1%", textAlign: "center" }}>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingSaveABB}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <h6
                style={{
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                CARICAMENTO...
              </h6>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SAVE_ABB_POPUP;
