import React, { useState, useEffect } from "react";
import "../../App.css";

import BeatLoader from "react-spinners/BeatLoader";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import OFF_DETAILS_VALUTATION_POPUP from "../../popup/OFFER_POPUP/OFF_DETAILS_VALUTATION_POPUP";

import EXP_ANALYSIS_OFF_COMPLETE from "../../exports/OFF/EXP_ANALYSIS_OFF_COMPLETE";

import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import BrowserUpdatedOutlinedIcon from "@mui/icons-material/BrowserUpdatedOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";

import offersGroupService from "../../services/offers_group";
import offersResumeService from "../../services/offers_resume";
import acquistatoService from "../../services/acquistato";
import garepubService from "../../services/garapub";

import { OFF_MAIN_COLUMNS } from "../../colums/OFF_ANALYSIS";

//FUNZIONI
import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const OffersAnalysis = ({ user }) => {
  const [offersGroupList, setOffersGroupList] = useState(null);
  // console.log("offersGroupList", offersGroupList);

  const [offersListSelected, setOffersListSelected] = useState(null);
  const [offersListDownloaded, setOffersListDownloaded] = useState(null);
  // console.log("offersListDownloaded", offersListDownloaded);

  const [loadingOffersGroupList, setLoadingOffersGroupList] = useState(true);
  const [loadingSingleOffersList, setLoadingSingleOffersList] = useState(false);

  const [offersListSuppliers, setOffersListSuppliers] = useState(null);

  const [singleOfferSelected, setSingleOfferSelected] = useState(null);

  const [flagCompletezzaOFF, setFlagCompletezzaOFF] = useState(false);
  const [flagAnalisiOFF, setFlagAnalisiOFF] = useState(false);

  const [offersResumeList, setOffersResumeList] = useState(null);
  const [offersResumeSelected, setOffersResumeSelected] = useState(null);
  // const [offersResumeDownloaded, setOffersResumeDownloaded] = useState(null);

  const [completezzaOFF_OBJ, setCompletezzaOFF_OBJ] = useState(null);
  // console.log("completezzaOFF_OBJ", completezzaOFF_OBJ);

  const [loadingOffersResumeList, setLoadingOffersResumeList] = useState(true);

  const [loadingMultipleOfferAnalysis, setLoadingMultipleOfferAnalysis] =
    useState(false);

  const [supplierNOTFoundFlag, setSupplierNOTFoundFlag] = useState(false);
  const [VAL_Flag, setVAL_Flag] = useState(false);

  const [analisiTipologiaFlag, setAnalisiTipologiaFlag] = useState(false);

  const [purchasedList, setPurchasedList] = useState(null);

  const [PurchasedSelected, setPurchasedSelected] = useState(null);

  const [loadingPurchasedList, setLoadingPurchasedList] = useState(true);

  const [loadingAnalisiTipologia, setLoadingAnalisiTipologia] = useState(false);

  const [listinoName, setListinoName] = useState(null);
  const [acquistatoName, setAcquistatoName] = useState(null);
  const [finalAnalysisOBJ, setFinalAnalysisOBJ] = useState(null);
  // console.log("finalAnalysisOBJ", finalAnalysisOBJ);

  const [subTabACQ, setSubTabACQ] = useState(null);
  const [subTabLIST, setSubTabLIST] = useState(null);
  const [subTabGARE, setSubTabGARE] = useState(null);

  // console.log("subTabACQ", subTabACQ);
  // console.log("subTabLIST", subTabLIST);
  // console.log("subTabGARE", subTabGARE);

  const [subTabOpen, setSubTabOpen] = useState(false);

  const [subTabMAIN, setSubTabMAIN] = useState(null);
  // console.log("subTabMAIN", subTabMAIN);

  useEffect(() => {
    if (!user.id) return;

    offersGroupService
      .getOffersGroupByUserId(user.id)
      .then((res) => {
        setOffersGroupList(res.data);
        setLoadingOffersGroupList(false);
      })
      .catch((error) => {
        console.error(
          "Errore durante il recupero dei listini dal server:",
          error
        );
      });

    offersResumeService
      .getOfferaResumeByUserId(user.id)
      .then((res) => {
        setOffersResumeList(res.data);
        setLoadingOffersResumeList(false);
      })
      .catch((error) => {
        console.error(
          "Errore durante il recupero dei listini dal server:",
          error
        );
      });

    acquistatoService.getAcquistatoByUserId(user.id).then((res) => {
      setPurchasedList(res.data);
      setLoadingPurchasedList(false);
    });
  }, [user.id]);

  //?Calcoli vari

  const calcoloDifferenzaEXP = (VecchioCosto, NuovoCosto) => {
    let diff = NuovoCosto - VecchioCosto;
    diff = Math.round(diff * 100) / 100;

    return diff;
  };

  const calcoloDifferenzaUnitariaEXP = (PrezzoSelezionato, AltroPrezzo) => {
    // console.log("PrezzoSelezionato", PrezzoSelezionato);
    // console.log("AltroPrezzo", AltroPrezzo);

    let diff = PrezzoSelezionato - AltroPrezzo;
    diff = Math.round(diff * 100000) / 100000;

    // console.log("diff", diff);

    return diff;
  };
  const handleSubTabClose = () => {
    setSubTabMAIN(null);

    setSubTabACQ(null);
    setSubTabLIST(null);
    setSubTabGARE(null);

    setSubTabOpen(false);
  };

  const handleSingleOfferDownload = async () => {
    setLoadingSingleOffersList(true);

    let suppList_TEMP = [];
    await offersGroupService
      .getOffersGroupByOffersGroupId(offersListSelected.id)
      .then((res) => {
        setOffersListDownloaded(res.data);
        let id_count = 0;
        res.data.offerte_inserite.map((Selected) => {
          let temp = {
            id_temp: id_count.toString(),
            ragione_sociale: Selected.ragione_sociale,
            cf_piva: Selected.cf_piva,
          };

          suppList_TEMP.push(temp);
          id_count = id_count + 1;
          return res.data.offerte_inserite;
        });

        const sortedData = suppList_TEMP.sort((a, b) =>
          a.ragione_sociale.localeCompare(b.ragione_sociale)
        );

        setOffersListSuppliers(sortedData);
        setLoadingSingleOffersList(false);
      });
  };

  const handleResetOfferDownload = () => {
    setOffersListSelected(null);
    setOffersListDownloaded(null);
    setOffersListSuppliers(null);
  };

  const handleBackToOffer = () => {
    setFlagCompletezzaOFF(false);
    setFlagAnalisiOFF(false);

    setSingleOfferSelected(null);

    setVAL_Flag(false);
    setSupplierNOTFoundFlag(false);
    setCompletezzaOFF_OBJ(null);
    setOffersResumeSelected(null);

    setFinalAnalysisOBJ(null);
    setListinoName(null);
    setAcquistatoName(null);
    setAnalisiTipologiaFlag(false);
  };

  const handleSelectSingleOffer = async (SupplierSelected) => {
    if (SupplierSelected !== null) {
      let temp = {};

      await offersListDownloaded.offerte_inserite.map((OFF_SEL) => {
        if (OFF_SEL.ragione_sociale === SupplierSelected.ragione_sociale) {
          temp = OFF_SEL;
        }

        return offersListDownloaded.offerte_inserite;
      });

      setSingleOfferSelected(temp);
    }
  };

  const handleSelectMultipleOffer = (newValue) => {
    if (newValue === null) {
      // setCompletezzaOFF_OBJ(null);
      // setVAL_Flag(false);
      setOffersResumeSelected(newValue);
    } else {
      setOffersResumeSelected(newValue);
    }
  };

  const createOffersResumeView = async (offersResumeTEMP) => {
    let finalOBJ = [];
    let id_count = 0;
    await offersResumeTEMP.offerte_inserite.map((OFF_SEL) => {
      OFF_SEL.offerta_obj.map((SINGLE_OFF_SEL) => {
        let temp = {
          id_temp: id_count,

          ragione_sociale: OFF_SEL.ragione_sociale,
          cf_piva: OFF_SEL.cf_piva,
          data_inserimento: OFF_SEL.data_inserimento,
          ordine_min: OFF_SEL.ordine_min,

          aic: SINGLE_OFF_SEL.aic,
          atc: SINGLE_OFF_SEL.atc,
          atc_complementare: SINGLE_OFF_SEL.atc_complementare,
          codice_gruppo_ospedaliero: SINGLE_OFF_SEL.codice_gruppo_ospedaliero,
          data_protocollo: SINGLE_OFF_SEL.data_protocollo,
          date: SINGLE_OFF_SEL.date,
          descrizione_atc: SINGLE_OFF_SEL.descrizione_atc,
          descrizione_gruppo_ospedaliero:
            SINGLE_OFF_SEL.descrizione_gruppo_ospedaliero,
          dosaggio: SINGLE_OFF_SEL.dosaggio,
          fascia: SINGLE_OFF_SEL.fascia,
          filef_flag: SINGLE_OFF_SEL.filef_flag,
          forma_farmaceutica: SINGLE_OFF_SEL.forma_farmaceutica,
          is_checked: SINGLE_OFF_SEL.is_checked,
          iva: SINGLE_OFF_SEL.iva,
          nome_commerciale: SINGLE_OFF_SEL.nome_commerciale,
          numero_protocollo: SINGLE_OFF_SEL.numero_protocollo,
          numero_unita_confezione: SINGLE_OFF_SEL.numero_unita_confezione,
          prezzo_netto_exfactory_confezione:
            SINGLE_OFF_SEL.prezzo_netto_exfactory_confezione,
          prezzo_netto_exfactory_unitario:
            SINGLE_OFF_SEL.prezzo_netto_exfactory_unitario,
          prezzo_pub_confezione: SINGLE_OFF_SEL.prezzo_pub_confezione,
          prezzo_pub_unitario: SINGLE_OFF_SEL.prezzo_pub_unitario,
          prezzo_unitario: SINGLE_OFF_SEL.prezzo_unitario,
          principio_attivo: SINGLE_OFF_SEL.principio_attivo,
          sconto_precentuale_prezzo_pub:
            SINGLE_OFF_SEL.sconto_precentuale_prezzo_pub,
        };

        id_count = id_count + 1;

        finalOBJ.push(temp);
        return OFF_SEL.offerta_obj;
      });

      return offersResumeTEMP.offerte_inserite;
    });

    return finalOBJ;
  };

  const createOfferListSuppSel = (offerList, suppSel) => {
    let offerListTEMP = [...offerList];

    let newListTEMP = offerListTEMP.filter(
      (SEL) => SEL.ragione_sociale === suppSel
    );
    return newListTEMP;
  };

  const handleValutazioneOFF = async () => {
    setLoadingMultipleOfferAnalysis(true);

    // console.log("offersResumeSelected", offersResumeSelected);
    // console.log("singleOfferSelected", singleOfferSelected);

    let singleOfferListTEMP = [...singleOfferSelected.offerta_obj];

    let ListVAL_TEMP =
      await offersResumeService.getOffersResumetByOffersResumetId(
        offersResumeSelected.id
      );

    let OFFERS_LIST_TEMP = await createOffersResumeView(ListVAL_TEMP.data);

    // console.log("singleOfferListTEMP", singleOfferListTEMP);
    // console.log("ListVAL_TEMP", ListVAL_TEMP);

    let offerListTEMP = await createOfferListSuppSel(
      OFFERS_LIST_TEMP,
      singleOfferSelected?.ragione_sociale
    );

    // console.log("offerListTEMP", offerListTEMP);

    if (offerListTEMP.length === 0) {
      setSupplierNOTFoundFlag(true);
    } else {
      let TROVATI = [];
      let NON_TROVATI = [];
      let NUOVI = [];

      let dataInizioOFF = singleOfferSelected.offerta_obj[0]?.date[0];
      let dataFineOFF = singleOfferSelected.offerta_obj[0]?.date[1];
      let protocolNumber =
        singleOfferSelected.offerta_obj[0]?.numero_protocollo;

      offerListTEMP.map((Selected) => {
        singleOfferListTEMP.map((SelectedOFF) => {
          if (Selected.aic === SelectedOFF.aic) {
            let temp = {
              aic: SelectedOFF.aic,
              dataInizio_offerta: dataInizioOFF,
              dataFine_offerta: dataFineOFF,
              numero_protocollo: protocolNumber,
              nome_commerciale: SelectedOFF.nome_commerciale,
              atc: SelectedOFF.atc,
              atc_complementare: SelectedOFF.atc_complementare,
              descrizione_atc: SelectedOFF.descrizione_atc,
              prezzo_offerta: SelectedOFF.prezzo_unitario,
              principio_attivo: SelectedOFF.principio_attivo,
              descrizione_gruppo_ospedaliero:
                SelectedOFF.descrizione_gruppo_ospedaliero,

              prezzo_offertaList: Selected.prezzo_unitario,
            };

            TROVATI.push(temp);
          }

          return singleOfferListTEMP;
        });

        return offerListTEMP;
      });

      offerListTEMP.map((Selected) => {
        let nonTrovatoFlag = true;
        TROVATI.map((SelectedOFFTROVATI) => {
          if (SelectedOFFTROVATI.aic === Selected.aic) {
            nonTrovatoFlag = false;
          }
          return TROVATI;
        });

        if (nonTrovatoFlag) {
          NON_TROVATI.push(Selected);
        }
        return offerListTEMP;
      });

      singleOfferListTEMP.map((SelectedOFF) => {
        let nuovoFlag = true;
        TROVATI.map((SelectedOFFTROVATI) => {
          if (SelectedOFFTROVATI.aic === SelectedOFF.aic) {
            nuovoFlag = false;
          }
          return TROVATI;
        });
        if (nuovoFlag) {
          NUOVI.push(SelectedOFF);
        }
        return singleOfferListTEMP;
      });

      setCompletezzaOFF_OBJ({
        FORNITORE: singleOfferSelected.ragione_sociale,
        TROVATI: TROVATI,
        NON_TROVATI: NON_TROVATI,
        NUOVI: NUOVI,
      });

      setSupplierNOTFoundFlag(false);
    }
    setVAL_Flag(true);
    setLoadingMultipleOfferAnalysis(false);
  };

  const foundMIN_GP_OSP = async (
    offersWithGP_OSP,
    purchasedTEMP,
    offerListTEMP
  ) => {
    // Mappa gruppo offerte
    offersWithGP_OSP.forEach((Selected) => {
      let ACQ_OSP_LIST = [];
      let OFF_OSP_LIST = [];

      purchasedTEMP.acquistato_inserito.forEach((SelectedACQ) => {
        if (
          Selected.codice_gruppo_ospedaliero ===
          SelectedACQ.codice_gruppo_ospedaliero
        ) {
          ACQ_OSP_LIST.push(SelectedACQ);
        }
      });

      if (ACQ_OSP_LIST.length > 0) {
        ACQ_OSP_LIST.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
      }

      offerListTEMP.forEach((SelectedOFF) => {
        if (
          Selected.descrizione_gruppo_ospedaliero ===
          SelectedOFF.descrizione_gruppo_ospedaliero
        ) {
          OFF_OSP_LIST.push(SelectedOFF);
        }
      });

      if (OFF_OSP_LIST.length > 0) {
        OFF_OSP_LIST.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
      }

      Selected.gp_osp_min_acquistato_LISTA = ACQ_OSP_LIST;
      Selected.gp_osp_min_listino_offerte_LISTA = OFF_OSP_LIST;
    });

    const GP_OSP_List = offersWithGP_OSP
      .filter((Selected) => Selected.codice_gruppo_ospedaliero !== "")
      .map((Selected) => Selected.codice_gruppo_ospedaliero);

    const GPSOPList_DoppiRimossi = [...new Set(GP_OSP_List)];

    const GP_List_GPOSP = await garepubService.getGPOSP(GPSOPList_DoppiRimossi);

    offersWithGP_OSP.forEach((Selected) => {
      let GP_OSP_LIST_FIN = [];

      GP_List_GPOSP.forEach((element) => {
        if (
          element.data.length > 0 &&
          element.data[0].codice_gruppo_ospedaliero ===
            Selected.codice_gruppo_ospedaliero
        ) {
          element.data.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
          GP_OSP_LIST_FIN = element.data;
        }
      });

      Selected.gp_osp_min_gare_pub_LISTA = GP_OSP_LIST_FIN;
    });

    return offersWithGP_OSP;
  };

  const handleAnalisiTipologia = async () => {
    setLoadingAnalisiTipologia(true);

    try {
      const [OFFERS_GROUP_TEMP, OFFERS_LIST_TEMP, PURCHASED_TEMP] =
        await Promise.all([
          createOffersResumeView(offersListDownloaded),
          offersResumeService.getOffersResumetByOffersResumetId(
            offersResumeSelected.id
          ),
          acquistatoService.getAcquistatoByPurchasedId(PurchasedSelected.id),
        ]);

      const OFFERS_LIST_TEMP_ELAB = await createOffersResumeView(
        OFFERS_LIST_TEMP.data
      );

      let senzaGP_OSP = [];
      let conGP_OSP = [];

      OFFERS_GROUP_TEMP.forEach((SelectedOFF) => {
        if (!SelectedOFF.descrizione_gruppo_ospedaliero) {
          senzaGP_OSP.push(SelectedOFF);
        } else {
          conGP_OSP.push(SelectedOFF);
        }
      });

      let OFF_SENZA_GP_OSP = senzaGP_OSP.map((Selected) => ({
        id_temp: Selected.id_temp,
        fornitore: {
          ragione_sociale: Selected.ragione_sociale,
          cf_piva: Selected.cf_piva,
        },
        numero_protocollo: Selected.numero_protocollo,
        data_inizio: Selected.date[0],
        data_fine: Selected.date[1],
        aic: Selected.aic,
        nome_commerciale: Selected.nome_commerciale,
        atc: Selected.atc,
        atc_complementare: Selected.atc_complementare,
        descrizione_atc: Selected.descrizione_atc,
        principio_attivo: Selected.principio_attivo,
        codice_gruppo_ospedaliero: null,
        descrizione_gruppo_ospedaliero: null,
        prezzo_unitario: Math.round(Selected.prezzo_unitario * 100000) / 100000,
        gp_osp_min_acquistato_LISTA: [],
        gp_osp_min_listino_offerte_LISTA: [],
        gp_osp_min_gare_pub_LISTA: [],
      }));

      const listMIN_GP_OSP = await foundMIN_GP_OSP(
        conGP_OSP,
        PURCHASED_TEMP.data,
        OFFERS_LIST_TEMP_ELAB
      );

      let OBJ_ELAB = listMIN_GP_OSP.concat(OFF_SENZA_GP_OSP);

      //!SBAGLIA QUALCOSA NEI SINGOLI CALCOLI SE CI SONO PIU ACQ IN LISTA...

      OBJ_ELAB.forEach((selectedOff) => {
        let quantitaTotale = 0;
        let costoTotale = 0;
        let conteggioAcquisti = 0;

        // Verifica se la descrizione del gruppo ospedaliero non è nulla
        if (selectedOff.descrizione_gruppo_ospedaliero != null) {
          // Verifica se ci sono elementi nella lista degli acquisti
          if (selectedOff.gp_osp_min_acquistato_LISTA.length > 0) {
            // Itera su ciascun elemento acquistato selezionato
            selectedOff.gp_osp_min_acquistato_LISTA.forEach((acquisto) => {
              costoTotale += calcoloCosto(
                acquisto?.prezzo_unitario,
                acquisto?.quantita
              );
              quantitaTotale += acquisto?.quantita;
              conteggioAcquisti += 1;

              let costoTemp = calcoloCosto(
                acquisto?.prezzo_unitario,
                acquisto?.quantita
              );
              let differenzaTemp = calcoloDifferenzaEXP(
                costoTemp,
                calcoloCosto(selectedOff?.prezzo_unitario, acquisto?.quantita)
              );

              // Aggiorna l'elemento acquistato con i valori calcolati
              acquisto.costo_TEMP = costoTemp;
              acquisto.diff_TEMP = differenzaTemp;
              acquisto.diff_UNITARIA = calcoloDifferenzaUnitariaEXP(
                selectedOff?.prezzo_unitario,
                acquisto?.prezzo_unitario
              );
            });

            let costoOffertaTotale = calcoloCosto(
              selectedOff?.prezzo_unitario,
              quantitaTotale
            );

            // Se ci sono più di un acquisto, aggiunge un oggetto riassuntivo alla lista
            if (conteggioAcquisti > 1) {
              let differenzaTotaleTemp = calcoloDifferenzaEXP(
                costoTotale,
                costoOffertaTotale
              );
              selectedOff.gp_osp_min_acquistato_LISTA.push({
                _id: 1,
                aic: "TOT",
                nome_commerciale: null,
                fornitore: null,
                prezzo_unitario: null,
                diff_unitaria_TEMP: null,
                costo_TEMP: costoTotale,
                diff_TEMP: differenzaTotaleTemp,
                quantita: quantitaTotale,
              });
            }

            // Aggiorna la lista delle offerte di listino con i valori calcolati
            selectedOff.gp_osp_min_listino_offerte_LISTA.forEach((sel) => {
              let costoTemp = calcoloCosto(
                sel?.prezzo_unitario,
                quantitaTotale
              );

              let differenzaTemp = calcoloDifferenzaEXP(
                costoTemp,
                costoOffertaTotale
              );

              sel.costo_TEMP = costoTemp;
              sel.diff_TEMP = differenzaTemp;
              sel.diff_UNITARIA = calcoloDifferenzaUnitariaEXP(
                selectedOff?.prezzo_unitario,
                sel?.prezzo_unitario
              );
            });

            // Aggiorna la lista delle gare pubbliche con i valori calcolati
            selectedOff.gp_osp_min_gare_pub_LISTA.forEach((sel) => {
              let costoTemp = calcoloCosto(
                sel?.prezzo_unitario,
                quantitaTotale
              );

              let differenzaTemp = calcoloDifferenzaEXP(
                costoTemp,
                costoOffertaTotale
              );

              sel.costo_TEMP = costoTemp;
              sel.diff_TEMP = differenzaTemp;
              sel.diff_UNITARIA = calcoloDifferenzaUnitariaEXP(
                selectedOff?.prezzo_unitario,
                sel?.prezzo_unitario
              );
            });

            selectedOff.quantita_tot = quantitaTotale;
          } else {
            // Se non ci sono elementi nella lista degli acquisti, imposta il costo e la differenza a null nelle altre liste
            selectedOff.gp_osp_min_listino_offerte_LISTA.forEach((sel) => {
              sel.costo_TEMP = null;
              sel.diff_TEMP = null;
              sel.diff_UNITARIA = calcoloDifferenzaUnitariaEXP(
                selectedOff?.prezzo_unitario,
                sel?.prezzo_unitario
              );
            });

            selectedOff.gp_osp_min_gare_pub_LISTA.forEach((sel) => {
              sel.costo_TEMP = null;
              sel.diff_TEMP = null;
              sel.diff_UNITARIA = calcoloDifferenzaUnitariaEXP(
                selectedOff?.prezzo_unitario,
                sel?.prezzo_unitario
              );
            });
          }

          //?SECONDA PARTE PER LE DIFFERENZE UNITARIE
        }
      });

      setFinalAnalysisOBJ(OBJ_ELAB);
      setListinoName(OFFERS_LIST_TEMP.data.nome_listino);
      setAcquistatoName(PURCHASED_TEMP.data.nome_salvataggio);
      setAnalisiTipologiaFlag(true);
    } catch (error) {
      console.error("Error in handleAnalisiTipologia:", error);
    } finally {
      setLoadingAnalisiTipologia(false);
    }
  };

  const handleViewDetailsTab = (params) => {
    let new_obj = [params.row];

    if (new_obj[0]?.descrizione_gruppo_ospedaliero !== null) {
      //?CASO CON GRUPPO OSPEDALIERO

      setSubTabMAIN(new_obj);

      if (new_obj[0]?.gp_osp_min_acquistato_LISTA.length > 0) {
        setSubTabACQ(new_obj[0]?.gp_osp_min_acquistato_LISTA);
      }

      if (new_obj[0]?.gp_osp_min_listino_offerte_LISTA.length > 0) {
        setSubTabLIST(new_obj[0]?.gp_osp_min_listino_offerte_LISTA);
      }

      if (new_obj[0]?.gp_osp_min_gare_pub_LISTA.length > 0) {
        setSubTabGARE(new_obj[0]?.gp_osp_min_gare_pub_LISTA);
      }
    } else {
      //   //?CASO SENZA GRUPPO OSPEDALIERO
      setSubTabMAIN(new_obj);
      setSubTabACQ(null);
      setSubTabLIST(null);
      setSubTabGARE(null);
    }

    setSubTabOpen(true);
  };

  return (
    <>
      <div className="singleOfferPage">
        <ThemeProvider theme={theme}>
          {" "}
          {!flagCompletezzaOFF && !flagAnalisiOFF && (
            <>
              <div>
                {offersListSelected !== null ||
                offersListDownloaded !== null ? (
                  <>
                    <div
                      style={{
                        paddingTop: "0.2%",
                        paddingLeft: "1%",
                        display: "inline-block",
                        width: "25%",
                        textAlign: "left",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="redtest"
                        startIcon={<ChevronLeftOutlinedIcon />}
                        onClick={handleResetOfferDownload}
                      >
                        INDIETRO
                      </Button>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      paddingTop: "0.2%",
                      display: "inline-block",
                      width: "25%",
                    }}
                  ></div>
                )}

                <div
                  style={{
                    paddingTop: "0.2%",
                    display: "inline-block",
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  <h2 style={{ fontWeight: "bold", color: "#1976d2" }}>
                    CONFRONTO OFFERTE
                  </h2>
                </div>
                <div
                  style={{
                    paddingTop: "0.2%",
                    paddingRight: "1%",
                    display: "inline-block",
                    width: "25%",
                    textAlign: "right",
                  }}
                ></div>
              </div>
              {loadingOffersGroupList ? (
                <div style={{ textAlign: "center", paddingTop: "2%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingOffersGroupList}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              ) : (
                <>
                  {offersListDownloaded === null && (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={offersGroupList}
                      getOptionLabel={(option) => option.anno}
                      sx={{
                        width: "100%",
                        paddingTop: "2%",
                        paddingLeft: "35%",
                        paddingRight: "35%",
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="OFFERTE" />
                      )}
                      value={offersListSelected}
                      onChange={(e, newValue) => {
                        setOffersListSelected(newValue);
                      }}
                    />
                  )}
                  {offersListSelected !== null &&
                    offersListDownloaded === null && (
                      <>
                        <div
                          style={{
                            paddingTop: "2%",
                            paddingLeft: "35%",
                            paddingRight: "35%",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="bluetest"
                            startIcon={<BrowserUpdatedOutlinedIcon />}
                            onClick={handleSingleOfferDownload}
                          >
                            CARICA SELEZIONATO
                          </Button>
                        </div>
                        {loadingSingleOffersList && (
                          <div
                            style={{ textAlign: "center", paddingTop: "2%" }}
                          >
                            <BeatLoader
                              color={"#1976d2"}
                              loading={loadingSingleOffersList}
                              size={30}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                            <h6
                              style={{
                                fontFamily: "Calibri",
                                color: "#1976d2",
                              }}
                            >
                              CARICAMENTO...
                            </h6>
                          </div>
                        )}
                      </>
                    )}
                  {offersListDownloaded !== null && (
                    <>
                      <div
                        style={{
                          paddingTop: "5%",
                          display: "inline-block",
                          width: "40%",
                          textAlign: "right",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="bluetest"
                          startIcon={<BrowserUpdatedOutlinedIcon />}
                          onClick={() => setFlagCompletezzaOFF(true)}
                        >
                          COMPLETEZZA OFFERTE
                        </Button>
                      </div>

                      <div
                        style={{
                          paddingTop: "5%",
                          display: "inline-block",
                          width: "10%",
                          textAlign: "center",
                        }}
                      ></div>

                      <div
                        style={{
                          paddingTop: "5%",
                          display: "inline-block",
                          width: "40%",
                          textAlign: "left",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="bluetest"
                          startIcon={<BrowserUpdatedOutlinedIcon />}
                          onClick={() => setFlagAnalisiOFF(true)}
                        >
                          ANALISI OFFERTE
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {flagCompletezzaOFF && !flagAnalisiOFF && (
            <>
              <div>
                <div
                  style={{
                    paddingTop: "0.2%",
                    paddingLeft: "1%",
                    display: "inline-block",
                    width: "25%",
                    textAlign: "left",
                  }}
                >
                  <Button
                    variant="contained"
                    color="redtest"
                    startIcon={<ChevronLeftOutlinedIcon />}
                    onClick={handleBackToOffer}
                  >
                    INDIETRO
                  </Button>
                </div>
                <div
                  style={{
                    paddingTop: "0.2%",
                    display: "inline-block",
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  <h2 style={{ fontWeight: "bold", color: "#1976d2" }}>
                    COMPLETEZZA OFFERTE
                  </h2>
                </div>
                <div
                  style={{
                    paddingTop: "0.2%",
                    paddingRight: "1%",
                    display: "inline-block",
                    width: "25%",
                    textAlign: "right",
                  }}
                ></div>
              </div>
              {singleOfferSelected === null ? (
                <div>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={offersListSuppliers}
                    getOptionLabel={(option) => option.ragione_sociale}
                    sx={{
                      width: "100%",
                      paddingTop: "2%",
                      paddingLeft: "35%",
                      paddingRight: "35%",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="OFFERTE SINGOLE" />
                    )}
                    // value={offersListSuppliersSelected}
                    onChange={(e, newValue) => {
                      handleSelectSingleOffer(newValue);
                    }}
                  />
                </div>
              ) : (
                <div>
                  {loadingOffersResumeList ? (
                    <div style={{ textAlign: "center", paddingTop: "2%" }}>
                      <BeatLoader
                        color={"#1976d2"}
                        loading={loadingOffersResumeList}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                      <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                        CARICAMENTO...
                      </h6>
                    </div>
                  ) : (
                    <>
                      <p style={{ color: "#1976d2" }}>
                        SELEZIONA IL LISTINO SUL QUALE VALUTARE LA COMPLETEZZA
                        DELL'OFFERTA
                      </p>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={offersResumeList}
                        getOptionLabel={(option) => option.nome_listino}
                        sx={{
                          width: "100%",
                          paddingTop: "2%",
                          paddingLeft: "35%",
                          paddingRight: "35%",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Listini" />
                        )}
                        value={offersResumeSelected}
                        onChange={(e, newValue) => {
                          handleSelectMultipleOffer(newValue);
                        }}
                      />
                    </>
                  )}

                  {offersResumeSelected !== null && (
                    <>
                      <div
                        style={{
                          paddingTop: "2%",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color={VAL_Flag ? "success" : "bluetest"}
                          startIcon={<QueryStatsOutlinedIcon />}
                          onClick={handleValutazioneOFF}
                        >
                          ESEGUI VALUTAZIONE
                        </Button>
                      </div>

                      {loadingMultipleOfferAnalysis && (
                        <div style={{ textAlign: "center", paddingTop: "2%" }}>
                          <BeatLoader
                            color={"#1976d2"}
                            loading={loadingMultipleOfferAnalysis}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                          <h6
                            style={{ fontFamily: "Calibri", color: "#1976d2" }}
                          >
                            CARICAMENTO...
                          </h6>
                        </div>
                      )}

                      {!supplierNOTFoundFlag ? (
                        <>
                          <div style={{ paddingTop: "2%" }}>
                            <EXP_ANALYSIS_OFF_COMPLETE
                              data={completezzaOFF_OBJ}
                              list_name={offersResumeSelected.nome_listino}
                              VAL_Flag={VAL_Flag}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <h3
                            style={{
                              fontFamily: "Calibri",
                              paddingTop: "2%",
                              color: "#f00",
                            }}
                          >
                            FORNITORE NON TROVATO!
                          </h3>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {!flagCompletezzaOFF && flagAnalisiOFF && (
            <>
              <div>
                <div
                  style={{
                    paddingTop: "0.2%",
                    paddingLeft: "1%",
                    display: "inline-block",
                    width: "25%",
                    textAlign: "left",
                  }}
                >
                  <Button
                    variant="contained"
                    color="redtest"
                    startIcon={<ChevronLeftOutlinedIcon />}
                    onClick={handleBackToOffer}
                  >
                    INDIETRO
                  </Button>
                </div>
                <div
                  style={{
                    paddingTop: "0.2%",
                    display: "inline-block",
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  <h2 style={{ fontWeight: "bold", color: "#1976d2" }}>
                    ANALISI OFFERTE
                  </h2>
                </div>
                <div
                  style={{
                    paddingTop: "0.2%",
                    paddingRight: "1%",
                    display: "inline-block",
                    width: "25%",
                    textAlign: "right",
                  }}
                ></div>
              </div>
              {!analisiTipologiaFlag ? (
                <>
                  <div>
                    {loadingOffersGroupList ? (
                      <div style={{ textAlign: "center", paddingTop: "2%" }}>
                        <BeatLoader
                          color={"#1976d2"}
                          loading={loadingOffersGroupList}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                        <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                          CARICAMENTO...
                        </h6>
                      </div>
                    ) : (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={offersResumeList}
                          getOptionLabel={(option) => option.nome_listino}
                          sx={{
                            width: "100%",
                            paddingLeft: "35%",
                            paddingRight: "35%",
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Listini" />
                          )}
                          value={offersResumeSelected}
                          onChange={(e, newValue) => {
                            setOffersResumeSelected(newValue);
                          }}
                        />
                      </>
                    )}

                    {loadingPurchasedList ? (
                      <div style={{ textAlign: "center", paddingTop: "2%" }}>
                        <BeatLoader
                          color={"#1976d2"}
                          loading={loadingPurchasedList}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                        <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                          CARICAMENTO...
                        </h6>
                      </div>
                    ) : (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={purchasedList}
                          getOptionLabel={(option) => option.nome_salvataggio}
                          sx={{
                            width: "100%",
                            paddingTop: "3%",
                            paddingLeft: "35%",
                            paddingRight: "35%",
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Acquistati" />
                          )}
                          value={PurchasedSelected}
                          onChange={(e, newValue) => {
                            setPurchasedSelected(newValue);
                          }}
                        />
                      </>
                    )}

                    {offersResumeSelected !== null &&
                      PurchasedSelected !== null && (
                        <>
                          <div
                            style={{
                              paddingTop: "2%",
                              textAlign: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="bluetest"
                              endIcon={<QueryStatsOutlinedIcon />}
                              onClick={handleAnalisiTipologia}
                            >
                              ESEGUI VALUTAZIONE
                            </Button>
                          </div>
                          {loadingAnalisiTipologia && (
                            <div
                              style={{ textAlign: "center", paddingTop: "2%" }}
                            >
                              <BeatLoader
                                color={"#1976d2"}
                                loading={loadingAnalisiTipologia}
                                size={30}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                              <h6
                                style={{
                                  fontFamily: "Calibri",
                                  color: "#1976d2",
                                }}
                              >
                                CARICAMENTO...
                              </h6>
                            </div>
                          )}
                        </>
                      )}
                  </div>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      height: "417px",
                      width: "100%",
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      paddingTop: "3%",
                      "& .theme_header": {
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontFamily: "Calibri",
                        fontSize: "16px",
                      },
                      "& .theme_cell": {
                        fontFamily: "Calibri",
                        fontSize: "17px",
                        border: "1px solid",
                      },
                    }}
                  >
                    <h3
                      style={{
                        paddingBottom: "0.2%",
                        textAlign: "center",
                        color: "#1976d2",
                        fontFamily: "Calibri",
                      }}
                    >
                      DOPPIO CLICK PER IL DETTAGLIO
                    </h3>
                    <DataGridPro
                      columns={OFF_MAIN_COLUMNS}
                      rows={finalAnalysisOBJ}
                      getRowId={(row) => row.id_temp}
                      headerHeight={40}
                      rowHeight={33}
                      onRowDoubleClick={handleViewDetailsTab}
                      disableColumnMenu
                      hideFooter
                    />
                  </Box>
                </>
              )}
            </>
          )}
          <OFF_DETAILS_VALUTATION_POPUP
            subTabOpen={subTabOpen}
            handleSubTabClose={handleSubTabClose}
            listinoName={listinoName}
            acquistatoName={acquistatoName}
            subTabMAIN={subTabMAIN}
            subTabACQ={subTabACQ}
            subTabLIST={subTabLIST}
            subTabGARE={subTabGARE}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default OffersAnalysis;
